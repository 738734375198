<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  Sort(:data-list="fromInfo" @sort="onSort")
  .row
    .col-lg-12
      router-link(to='/')
        b-button.width-md.mb-2(variant="outline-secondary") 
          //- i.ri-arrow-left-fill.back-icon
          span 回上一層

      .card
        .card-body
          .row 
            .col.header-title 投票區
          .row
            .col
              b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort) 編輯排序
          .row  
            .col 
              .card.col-lg-11(v-for="(item,index) of fromInfo" :key="item.id")
                .card-body.form-body
                  form
                    .row
                      .col-lg-4.my-1
                        multiselect(
                          :value="item.poll_id"
                          @input="selectVoteId($event, index)", 
                          :options="votes",
                          label="name",
                          track-by="id",
                          placeholder="請輸入投票ID",
                          select-label=""
                          @search-change="searchVoteList"
                        )
                      .col-lg-4.my-1
                        multiselect(
                          v-model="item.poll_type",
                          :options="pollTypeOptions",
                          label="name",
                          track-by="id",
                          placeholder="類型",
                          select-label=""
                        )
                      .col-lg-4.my-1
                        multiselect(
                          v-model="item.sub_type",
                          :options="typeOptions",
                          label="name",
                          track-by="id",
                          placeholder="選擇分類",
                          select-label=""
                        )

                      .col-lg-12.my-2
                        b-form-input.form-control(type="text", v-model="item.title" placeholder="請輸入標題")

                      .col-lg-12(v-if="!item.image")
                        .other-picture
                          vue-dropzone(
                            :ref="'dropzone-0' + index + '-img'"
                            :id="'dropzone-0' + index + '-img'", 
                            :key="'dropzone-0' + index + '-img'", 
                            ref="myVueDropzone", 
                            :use-custom-slot="true", 
                            :options="dropzoneOptions",
                            @vdropzone-success-multiple="($event, response) => uploadImageSuccess($event, response, index)"
                          )
                            .dz-message.needsclick
                              i.h1.text-muted.ri-upload-cloud-2-line
                              h4 上傳圖片
                      .col-lg-12.mt-2.row(v-else)
                        .col
                          .d-flex.align-items-end
                            .card.mb-0.shadow-none
                              .dropzone-previews
                                .base-view
                                  img.preview-img.avatar-sm.rounded.bg-light(:src='item.image' alt='')
                                  a.img-del-btn.btn.btn-link.btn-lg.text-muted(@click="deleteImg(index)")
                                    i.fe-x
                          p.mb-0.ml-2 (尺吋： 523 x 357) 

                      .col-12(v-if="fromInfo.length > 3")
                        .del-btn.float-right.width-auto(@click="removeItem(index)")
                          b-button.btn(size="lg" variant="primary")
                            i.mdi.mdi-close 
                          span.ml-1.mr-0 刪除

          .row(v-if="fromInfo.length < 6")
            .col
              b-button.col-lg-11.add-btn.width-lg.mb-2(variant="outline-primary" @click="addNewOne(3)") ＋新增3則 （最多顯示6則） 

        .row.m-2.mt0
          .col
            b-button.action-btn.width-lg.mr-1(variant="primary" :disabled="saving" @click="saveSetting") {{ saving ? '儲存中...' : '儲存' }}
            b-button.action-btn.width-lg(variant="primary" :disabled="releasing" @click="releaseSetting") {{ releasing ? '發佈中...' : '發佈' }}



</template>
  
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import newsItem from "@/components/news-item/news-item"
import Multiselect from "vue-multiselect";
import vue2Dropzone from "vue2-dropzone";
import Sort from "@/components/sort"

/**
 * Starter component
 */
export default {
  name: 'HomeRegionVote',
  data () {
    return {
      title: "投票區",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "大首頁",
          href: "/",
        },
        {
          text: "投票區",
          active: true,
        }
      ],
      pollTypeOptions: [
        { id: 1, name: '選擇題' },
        { id: 2, name: '話題 PK' }
      ],
      typeOptions: [],
      votes: [],
      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD_SERVER_URI + "/media/create/news/default",
        method: "post",
        uploadMultiple: true,
        paramName: "files",
        headers: {
          "Authorization": 'Bearer ' + this.$root.common.getCookie('api-token'),
        },
        previewTemplate: this.template(),
      },
      saving: false,
      releasing: false,
      fromInfo: []
    };
  },
  mounted () {
    this.searchVoteList()
    this.getTypeOptions()
    this.getPollSetting()
  },
  methods: {
    // 處理圖片下載模組
    template () {
      return ` <div class="dropzone-previews">
            <div class="card mb-0 shadow-none">
              <img data-dz-thumbnail src="#" class="preview-img avatar-sm rounded bg-light" alt="">
              <!-- Button -->
              <a href="" class="img-del-btn btn btn-link btn-lg text-muted" data-dz-remove>
                  <i class="fe-x"></i>
              </a>
            </div>
        </div>`;
    },
    // 圖片上傳成功時
    uploadImageSuccess (_file, _response, _index) {
      this.fromInfo.splice(_index, 1, {
        ...this.fromInfo[_index],
        image: _response.data[0].url,
      })
    },
    getTypeOptions () {
      const vm = this
      this.$root.apis.getVoteList({
        per_page: 100
      }, function (_response) {
        const types = _response.body.data.polls.map(_vote => _vote.sub_type)
        vm.typeOptions = types.filter((value, index, array) => array.findIndex(item => item.id === value.id) === index)
      })
    },
    getPollSetting () {
      const vm = this
      this.$root.apis.getPollSetting(
        function (_response) {
          vm.fromInfo = _response.body.data.polls.map(_poll => ({
            ..._poll,
            poll_id: { id: _poll.poll_id, name: _poll.poll_id },
            poll_type: vm.pollTypeOptions.find(_type => _type.id === _poll.poll_type) || null,
            sub_type: { id: _poll.type_info.id, name: _poll.type_info.name },
            title: _poll.title,
            image: _poll.image,
            sort: _poll.sort,
          }))
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    },
    searchVoteList (_text) {
      let vm = this
      this.$root.apis.getVoteList({
        id: _text,
        search_source: 2,
        per_page: 100
      }, function (_response) {
        let polls = _response.body.data.polls
        vm.votes = polls.map(_vote => ({ id: _vote.id, name: _vote.id + ' | ' + _vote.title }))
      })
    },
    onSort (_sorted) {
      this.fromInfo = _sorted
    },
    addNewOne (_count) {
      const addItem = Array.from({ length: _count }, (_item, _index) => ({
        poll_id: null,
        poll_type: null,
        sub_type: null,
        title: '',
        image: '',
        sort: null
      }));
      this.fromInfo = this.fromInfo.concat(addItem)
    },
    removeItem (_index) {
      this.fromInfo.splice(_index, 1)
    },
    selectVoteId (_selected, _index) {
      const vm = this
      this.$root.apis.getVote(_selected.id,
        function (_response) {
          const _vote = _response.body.data
          vm.fromInfo.splice(_index, 1, {
            ...vm.fromInfo[_index],
            poll_id: _selected,
            poll_type: vm.pollTypeOptions.find(_type => _type.id === _vote.type) || null,
            sub_type: vm.typeOptions.find(_type => _type.id === _vote.sub_type.id) || null,
            title: _vote.title,
            image: _vote.image,
          })
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    },
    deleteImg (_index) {
      this.fromInfo.splice(_index, 1, {
        ...this.fromInfo[_index],
        image: '',
      })
    },
    saveSetting () {
      this.saving = true
      const vm = this
      const data = {
        items: this.fromInfo.map(_item => ({
          pollId: _item.poll_id.id,
          typeId: _item.sub_type.id,
          title: _item.title,
          image: _item.image,
        }))
      }
      this.$root.apis.updatePollSetting(
        data,
        function (_response) {
          vm.$root.common.showSingleNotify("更新成功")
          vm.saving = false
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorNotify(_error.body.data)
          vm.saving = false
        }
      )
    },
    releaseSetting () {
      this.releasing = true
      const vm = this
      this.$root.apis.releasePollSetting(
        function (_response) {
          vm.$root.common.showSingleNotify("發佈成功")
          vm.releasing = false
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorNotify(_error.body.data)
          vm.releasing = false
        }
      )
    }
  },
  components: {
    Layout,
    PageHeader,
    newsItem,
    Multiselect,
    vueDropzone: vue2Dropzone,
    Sort,
  },
};
</script>

<style lang="sass" scoped>
::v-deep.nav-tabs
  background: #e2e8f0
::v-deep.nav-tabs .nav-link
  font-weight: 400
  font-size: 15px
  padding: 0.8rem 2.5rem

.back-icon
  margin-right: 5px
  position: relative
  font-size: 14px
  top: 1.2px
.add-btn
  width: 100%
  border: dashed 1px

::v-deep.vue-dropzone
  padding: 0
  width: 100%
  height: 100%
  max-height: 170px
  max-width: 282px

::v-deep .dropzone-previews
  width: 100%
  height: 100%
  .img-del-btn
    position: absolute
    right: -15px
    top: -10px
    background: #1e40b0
    border-radius: 50%
    padding: 0
    height: 30px
    width: 30px
    line-height: 30px
    cursor: pointer
    i
      color: #fff
      cursor: pointer

  img
    width: 100%
    height: 100%
    object-fit: cover


.card
  margin-bottom: 2rem
  .form-group
    display: flex
    justify-content: space-start
    align-items: center
    flex-wrap: wrap
    gap: 14px
    .search-box
      position: relative
      #tip
        padding: 0 10px 0 35px
        background-repeat: no-repeat
        background-position: 10px 7px
      .fa-search
        position: absolute
        top: 30%
        margin: auto
  .del-btn
    flex: 1
    display: flex
    justify-content: flex-end
    align-items: center
    .btn
      padding: 0rem .3rem
      border-radius: 50px
::v-deep .tab-pane
  width: 100%

.base-view
  max-width: 224px
  max-height: 153px
  width: 224px
  height: 153px
  position: relative
  img
    max-width: 224px
    max-height: 153px
    width: 224px
    height: 153px

.form-body
  overflow: scroll
  
@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 1rem 0
</style>